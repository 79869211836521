<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="card-title">{{date}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">KW {{cw}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment-timezone";

export default {
  components: {},

  data() {
    return {
      date: '',
      cw: ''
    };
  },
  created() {
    this.getNow()
    setInterval(() => {
      this.getNow();
    }, 100000)
  },
  methods: {
    getNow: function () {
      moment.locale("de");
      this.date = moment().format('dd, DD. MMMM YYYY')
      this.cw = moment().format('W')
    }
  },
};
</script>
<style>
</style>
