<template>
  <card>
    <template slot="header">
      <h5 class="card-title">Aufgaben</h5>
    </template>
    <div class="table-full-width table-tasks">
      <table class="table">
        <tbody>
          <tr v-for="task in tasks" :key="task.Id">
            <td>
              <p-checkbox v-model="task.done" disabled></p-checkbox>
            </td>
            <td class="text-left"><span v-if="task.done"><s>{{task.title}}</s></span><span v-else><router-link :to=task.link class="task-link">{{task.title}}</router-link></span></td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>
<script>
import Vue from "vue";
import moment from "moment-timezone";
import { db, firebaseApp } from "../../../../firebaseDb";
import store from "../../../../store";

export default {
  components: {},

  data() {
    return {
      tasks: [
        {Id: 1, title: 'Checkliste Betriebsbeginn HT ausfüllen', link: '/checklist/HTStart', done: false}, 
        {Id: 2, title: 'Checkliste Betriebsbeginn Kasse ausfüllen', link: '/checklist/KasseStart', done: false},
        {Id: 3, title: 'Checkliste Betriebsende HT ausfüllen', link: '/checklist/HTEnde', done: false}, 
        {Id: 4, title: 'Checkliste Betriebsende Kasse ausfüllen', link: '/checklist/KasseEnde', done: false},
        {Id: 6, title: 'Kassenabschluss erstellen', link: '/kassenabschluss', done: false}, 
        {Id: 7, title: 'Tagesprotokoll ausfüllen', link: '/ht/tagesprotokoll', done: false},
      ],
      location: store.getters.userlocation,
      reportName: moment().format('YYYYMMDD') + '-' + store.getters.userlocation
    };
  },
  created() {
    firebaseApp.auth().currentUser.getIdTokenResult().then((tokenResult) => {
      this.location = tokenResult.claims.location
    }).then(() => {
      this.reportName = moment().format('YYYYMMDD') + '-' + this.location
      // check Tagesprotokoll
      const tagesprotokollRef = db.collection('tagesreports/' + this.reportName + '/Parcours')
      tagesprotokollRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[5].done = true
        }
      })
      // check HTStart
      const htstartRef = db.collection('tagesreports/' + this.reportName + '/HTStart')
      htstartRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[0].done = true
        }
      })
      // check HTEnde
      const htendeRef = db.collection('tagesreports/' + this.reportName + '/HTEnde')
      htendeRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[2].done = true
        }
      })
      // check KasseStart
      const kassestartRef = db.collection('tagesreports/' + this.reportName + '/KasseStart')
      kassestartRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[1].done = true
        }
      })
      // check KasseEnde
      const kasseendeRef = db.collection('tagesreports/' + this.reportName + '/KasseEnde')
      kasseendeRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[3].done = true
        }
      })
      // check Kassenabschluss
      const kassenabschlussRef = db.collection('tagesreports/' + this.reportName + '/Kassenabschluss')
      kassenabschlussRef.get().then((doc) => {
        if(doc.docs.length > 0) {
          this.tasks[4].done = true
        }
      })
      // add and check Huettencheckliste if day = Wednesdays (3)
      if(moment().day() == 3) {
        this.tasks.push({Id: 5, title: 'Hüttencheckliste ausfüllen', link: '/checklist/Huette', done: false})
        const huetteRef = db.collection('tagesreports/' + this.reportName + '/Huette')
        huetteRef.get().then((doc) => {
          if(doc.docs.length > 0) {
            this.tasks[6].done = true
          }
        })
      }
    })
  },
  methods: {},
};
</script>
<style>
.task-link {
  text-decoration: none !important;
  color: #212529 !important;
}
</style>
