<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
      <div class="col-md-6 pr-3">
        <WidgetDate></WidgetDate>
      </div>
      <div class="col-md-6 pl-md-0">
        <WidgetWeather></WidgetWeather>
      </div>
    </div>
        <WidgetTrainer v-if="role > 0"></WidgetTrainer>
        <WidgetTasks v-if="role > 0"></WidgetTasks>
      </div>
      <div class="col-md-6">
        <WidgetHinweis></WidgetHinweis>
        <WidgetAnmerkungen v-if="role > 0"></WidgetAnmerkungen>
      </div>
    
    </div>

  </div>
</template>
<script>
  import Loading from 'src/components/KET/Layout/LoadingMainPanel.vue'
  import WidgetTrainer from "/src/components/KET/Views/Components/KETWidgetTrainer.vue";
  import WidgetAnmerkungen from "/src/components/KET/Views/Components/KETWidgetAnmerkungen.vue";
  import WidgetDate from "/src/components/KET/Views/Components/KETWidgetDate.vue";
  import WidgetWeather from "/src/components/KET/Views/Components/KETWidgetWeather.vue";
  import WidgetTasks from "/src/components/KET/Views/Components/KETWidgetTasks.vue";
  import WidgetHinweis from "/src/components/KET/Views/Components/KETWidgetHinweis.vue";
  import store from "../../../../store";
  import { provider, db, firebaseApp } from "../../../../firebaseDb";


  export default {
    components: {
      WidgetTrainer,
      WidgetAnmerkungen,
      WidgetDate,
      WidgetWeather,
      WidgetTasks,
      WidgetHinweis,
    },

    data () {
      return {
        role: store.getters.userrole,
      }
    },
    created () {
      firebaseApp.auth().currentUser.getIdTokenResult(true).then((tokenResult) => {
        this.role = tokenResult.claims.role;
      });
    }
  }

</script>
<style>

</style>
