<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-9">
            <h5 class="card-title">Heutige Trainer</h5>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12">
          <el-table
            :data="trainerToday"
            header-row-class-name="text-primary"
            empty-text="Es konnten keine Trainer gefunden werden."
            :default-sort = "{prop: 'todayStart', order: 'ascending'}"
          >
            <el-table-column prop="trainerName" label="Name"> </el-table-column>
            <el-table-column prop="todayStart" label="Schichtbeginn"> </el-table-column>
            <el-table-column prop="status" label="Retterstatus">
              <template slot-scope="scope">
                <el-tooltip
                  :content="
                    'Letzte Prüfung: ' + scope.row.trainerLetztePruefung
                  "
                  placement="left"
                >
                  <div v-if="scope.row.status == 1">
                    <i
                      class="bi bi-check-circle-fill"
                      style="font-size: 1.2rem; color: green"
                    ></i>
                  </div>
                </el-tooltip>
                <el-tooltip
                  :content="
                    'Letzte Prüfung: ' + scope.row.trainerLetztePruefung
                  "
                  placement="left"
                >
                  <div v-if="scope.row.status == 2">
                    <i
                      class="bi bi-exclamation-triangle-fill"
                      style="font-size: 1.2rem; color: orange"
                    ></i>
                  </div>
                </el-tooltip>
                <el-tooltip
                  :content="
                    'Letzte Prüfung: ' + scope.row.trainerLetztePruefung
                  "
                  placement="left"
                >
                  <div v-if="scope.row.status == 3">
                    <i
                      class="bi bi-exclamation-triangle-fill"
                      style="font-size: 1.2rem; color: red"
                    ></i>
                  </div>
                </el-tooltip>
                <el-tooltip
                  :content="
                    'Retter Light - Letzte Prüfung: ' + scope.row.trainerLetztePruefung
                  "
                  placement="left"
                >
                  <div v-if="scope.row.status == 4">
                    <i
                      class="bi bi-check-circle-fill"
                      style="font-size: 1.2rem; color: blue"
                    ></i>
                  </div>
                </el-tooltip>
                <el-tooltip content="Kein Retter" placement="left">
                  <div v-if="scope.row.status == 5">
                    <i class="bi bi-dash" style="font-size: 1.2rem"></i>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              class-name="action-buttons td-actions"
              align="right"
              label=""
              width="50"
            >
            <template slot-scope="props">
                  <p-button
                    type="neutral"
                    size="sm"
                    icon
                    @click="removeTrainerIsToday(props.$index, props.row)"
                  >
                    <i class="fa fa-remove"></i>
                  </p-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pt-2">
            <el-select v-model="addTrainer" filterable autocomplete clearable placeholder="Trainer hinzufügen" @change="addTrainerIsToday">
              <el-option
                v-for="item in trainerNotToday"
                :key="item.key"
                :label="item.trainerName"
                :value="item.key">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { db, firebaseApp } from "../../../../firebaseDb";
import { Table, TableColumn, Tooltip, Select, Option } from "element-ui";
import moment from "moment-timezone";
import store from "../../../../store";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      trainer: [],
      addTrainer: null,
      location: store.getters.userlocation,
    };
  },
  created() {
    firebaseApp.auth().currentUser.getIdTokenResult().then((tokenResult) => {
      this.location = tokenResult.claims.location
    }).then(() => {
      // get list of trainers
      const trainerRef = db.collection("trainer");
      trainerRef
        //.where("isToday", "==", true)
        .where('locationID', 'array-contains', this.location)
        .orderBy("Name")
        .onSnapshot((snapshotChange) => {
          this.trainer = [];
          snapshotChange.forEach((doc) => {
            let lastCheck = ''
            let lastCheckTimestamp = 0
            if(doc.data().RetterpruefungDate) {
              lastCheckTimestamp = doc.data().RetterpruefungDate.seconds * 1000
              lastCheck = moment(new Date(doc.data().RetterpruefungDate.seconds * 1000)).format("DD.MM.yyyy").toString()
            }
            var status;
            if (
              doc.data().isRetter &&
              doc.data().isRetterLight == true &&
              moment() - lastCheckTimestamp < 5184000000 && lastCheckTimestamp > 0
            ) {
              status = 4; // Retter light (blau)
            }
            else if (
              doc.data().isRetter &&
              moment() - lastCheckTimestamp < 4060800000 && lastCheckTimestamp > 0
            ) {
              status = 1; // Retter < 46 Tage (gruen)
            } else if (
              doc.data().isRetter &&
              (moment() - lastCheckTimestamp > 4060800000 && moment() - lastCheckTimestamp < 5184000000)
            ) {
              status = 2; // Retter > 46 Tage und < 60 Tage (gelb)
            } else if (
              doc.data().isRetter &&
              (moment() - lastCheckTimestamp > 5184000000 || lastCheckTimestamp == 0)
            ) {
              status = 3;  // Retter > 60 Tage (rot)
            } else if (!doc.data().isRetter) {
              status = 5; // kein Retter
            }
            var todayStartTime;
            if (doc.data().todayStart) {
              todayStartTime = moment(new Date(doc.data().todayStart.seconds * 1000)).format("HH:mm").toString() + ' Uhr'
            } else {
              todayStartTime = '-'
            }
            this.trainer.push({
              key: doc.id,
              trainerName: doc.data().Name,
              trainerID: doc.data().ID,
              isRetter: doc.data().isRetter,
              isToday: doc.data().isToday || false,
              isTodayLocation: doc.data().isTodayLocation || '',
              todayStart: todayStartTime,
              trainerLetztePruefung: lastCheck,
              status: status,
            });
          });
        });
    })
    
  },
  methods: {
    addTrainerIsToday() {
      const trainerRef = db.collection('trainer')
      trainerRef.doc(this.addTrainer).update({ isToday: true, isTodayLocation: this.location }).then(() => {
        this.addTrainer = ''
      })
    },
    removeTrainerIsToday(index, row) {
      const trainerRef = db.collection('trainer')
      trainerRef.doc(row.key).update({ isToday: false }).then(() => {
      })
    },
  },
  computed: {
    trainerToday() {
      return this.trainer.filter(function(item) {
        return item.isToday == true && item.isTodayLocation == store.getters.userlocation
      })
    },
    trainerNotToday() {
      return this.trainer.filter(item => item.isToday == false)
    },
  }
};
</script>
<style>
</style>
