<template>
  <div>
    <time-line type="simple" class="">
      <time-line-item
        class="timeline-inverted"
        v-for="anmerkung in anmerkungen"
        :key="anmerkung.key"
        :badgeType="anmerkung.type"
        badgeIcon="nc-icon nc-single-copy-04"
      >
        <badge slot="header" type="danger" v-if="anmerkung.important"
          >Wichtig!</badge
        >
        <p slot="content" style="white-space: pre-line;">{{ anmerkung.text }}</p>

        <h6 slot="footer" class="pt-2">
          <i class="fa fa-clock-o"></i>
          {{ anmerkung.timestamp }} Uhr
        </h6>
      </time-line-item>
    </time-line>
    <p class="pt-3 small">
      <i>Es werden nur die Anmerkungen der letzten 7 Tage angezeigt.</i>
    </p>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem, Badge } from "src/components/UIComponents";
import { db, firebaseApp } from "../../../../firebaseDb";
import moment from "moment-timezone";
import store from "../../../../store";

export default {
  components: {
    TimeLine,
    TimeLineItem,
    Badge,
  },

  data() {
    return {
      anmerkungen: [],
      location: store.getters.userlocation,
    };
  },
  created() {
    moment.locale("de");
    firebaseApp.auth().currentUser.getIdTokenResult().then((tokenResult) => {
      this.location = tokenResult.claims.location
    }).then(() => {
      var dateLimit = new Date(new Date().getTime() - 604800000); // 7 days ago
      const anmerkungenRef = db.collection("anmerkungen");
      anmerkungenRef
        .where('locationID', '==', this.location)
        .where("timestamp", ">=", dateLimit)
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshotChange) => {
          this.anmerkungen = [];
          snapshotChange.forEach((doc) => {
            var type = null;
            if (doc.data().important) {
              type = "danger";
            } else {
              type = "success";
            }
            this.anmerkungen.push({
              key: doc.id,
              text: doc.data().text,
              important: doc.data().important,
              timestamp: moment(new Date(doc.data().timestamp.seconds * 1000))
                .format("dddd, DD. MMM HH:mm")
                .toString(),
              type: type,
            });
          });
        });
    })
  },
  methods: {},
};
</script>
<style>
</style>
